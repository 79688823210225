import { useRouteLoaderData } from '@remix-run/react'
import lodash from 'lodash'
import { type loader as rootLoader } from '~/root'

const { find } = lodash

// Custom hook to get and memoize settings
function useSettings() {
	const data = useRouteLoaderData<typeof rootLoader>('root')

	return data.sitedata || []
}

export function useSetting(name: string) {
	const settings = useSettings()

	const setting = find(settings, { name: name })
	return setting ? setting.value : null
}

export function SiteTitlet() {
	const siteTitle = useSetting('site_title')
	return siteTitle || 'Site name'
}

export function TheSiteName(name: string) {
	const siteName = useSetting(name)
	return siteName || ''
}
